import React from "react";
import { Slider } from "../../slider";
// import slides from '../mock';
import slides from '../mock';
import slides2 from '../mock2';
import slides3 from '../mock3';
import slides4 from '../mock4';
import './service.css'

function Service() {
  return (
    // <div class="responsive-container container-for" id="service">
      <div className="all-container container-for" id="service">
        <div className="text-and-slides">
          <div className="service-text">
            <div className="recent-project">
              Our Recent Projects
            </div>
            <div className="project-infos">
              We have helped over 20,000+ homes and businesses with their flooring projects.
              Not only do we supply quality flooring products at great prices, we also have a 
              team of highly experienced floor layers.
            </div>
          </div>

          <div className="slider-in-service">
            <div className="big-slide">
              <Slider slides={slides} />
              <div className="second-slide">
                <Slider slides={slides2} />
              </div>
              
            </div>
            <div className="small-slides">
              <Slider slides={slides3} />
              <div className="second-slide">
                <Slider slides={slides4} />
              </div>
            </div>
            
            
          </div>
        </div>


      </div>
    // </div>
  );
}

export default Service;
