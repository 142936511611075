
import img0554 from './images/IMG_0554.JPG';
import img0555 from './images/IMG_0555.JPG';
import img0557 from './images/IMG_0557.JPG';


const data = [

    {
        "image": img0554,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0555,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0557,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    }
]


export default data;