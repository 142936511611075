import React, { useRef } from "react";
import "./serviceArea.css";
// import emailjs from '@emailjs/browser';
import emailjs from '@emailjs/browser'

function ServiceArea() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_9lfzesi', 'template_ewc4plw', form.current, 'lbamqGfWaG9BaNTkJ')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className="ServiceArea" id="ServiceArea">

      {/* <div className="service-area">
        <div className="flooring-materials">Service Area</div>
        <div className="service-offers">
          We offer service in this area but also outside this area
        </div>
      </div>
      <div className="map">

      </div> */}
      <div class="container">
        <div class="content">
          <div class="left-side">
            <div class="address details">
              <i class="fas fa-map-marker-alt"></i>
              <div class="topic">Address</div>
              <div class="text-one">Surkhet, NP12</div>
              <div class="text-two">Birendranagar 06</div>
            </div>
            <div class="phone details">
              <i class="fas fa-phone-alt"></i>
              <div class="topic">Phone</div>
              <div class="text-one">+0098 9893 5647</div>
              <div class="text-two">+0096 3434 5678</div>
            </div>
            <div class="email details">
              <i class="fas fa-envelope"></i>
              <div class="topic">Email</div>
              <div class="text-one">codinglab@gmail.com</div>
              <div class="text-two">info.codinglab@gmail.com</div>
            </div>
          </div>
          <div class="right-side">
            <div class="topic-text">Send us a message</div>
            <p>
              If you have any work from me or any types of quries related to my
              tutorial, you can send me message from here. It's my pleasure to
              help you.
            </p>
            <form onSubmit={sendEmail}>
              <div class="input-box">
                <input type="text" placeholder="Enter your name" />
              </div>
              <div class="input-box">
                <input type="text" placeholder="Enter your email" />
              </div>
              <div class="input-box">
                <textarea rows={40} type="text" placeholder="Enter your Message" />
              </div>
              <div class="button">
                <input className="send-button" type="submit" value="Send"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceArea;
