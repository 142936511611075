
import img0594 from './images/IMG_0594.JPG';
import img0592 from './images/IMG_0592.JPG';
import img0591 from './images/IMG_0591.JPG';

const data4 = [
    {
        "image": img0594,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0592,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0591,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    }
]

export default data4;