import React from "react";
import "./footer.css";
import facebooklogo from "./facebooklogo.png";
import { Link } from "react-scroll";

function Footer() {
  return (
    <div className="footer">
      <div className="sb__footer section__padding">
        <div className="sb__footer-links">
          <div className="sb__footer-links_div">
            <h4>Qualitätsfußböden
für Ihr Zuhause</h4>
            <p>Wir schätzen Ihr Vertrauen</p>
          </div>
          <div className="sb__footer-links_div">
            <h4>Links</h4>
            <Link to="main" smooth={true} duration={500}>
            <a href="main">
              <p>Home</p>
            </a>
            </Link>

            <Link to="service" smooth={true} duration={500}>
            <a href="service">
              <p>Service</p>
            </a>
            </Link>
            <Link to="testimonal" smooth={true} duration={500}>
            <a href="testestimonaltim">
              <p>Testimonial</p>
            </a>
            </Link>
          </div>

          <div className="sb__footer-links_div">
            <h4>Other Links</h4>
            <Link to="ServiceArea" smooth={true} duration={500}>
            <a href="ServiceArea">
                <p>Service Area</p>
              </a>
              </Link>
              <a href="home">
                <p>Employer</p>
              </a>
              <a href="home">
                <p>Employer</p>
              </a>
          </div>
          <div className="sb__footer-links_div"> 
          <Link to="ServiceArea" smooth={true} duration={500}>
            <h4>Contact Us</h4>
            </Link>
            <div className="socialmedia"></div>
            <p><img  src={facebooklogo}  alt="" /></p>
          </div>
        </div>


        <hr></hr>

        <div className="sb__footer-below">
          <div className="sb__footer-copyright"></div>
          <p className="sb__footer-below-text">
             @{new Date().getFullYear()} Bajqinca. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
