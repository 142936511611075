import React from "react";
import "./testimonal.css";
import Schwimmbader from "../images/IMG_0634.JPG";
import Bodenbelage from "../images/IMG_0579.JPG";
import zaunSysteme from "../images/IMG_0591.JPG";
import terrassenDielen from "../images/IMG_0563.JPG";

function Testimonal() {
  return (
    <div class="testimonal" id="testimonal">
      <div className="flooring-and-photos">
        <div className="flooring">
          <div className="flooring-materials">Flooring Materials</div>
          <div className="our-legacy">
            Our legacy dates back more than 150 years, when our founder made a
            simple but significant statement: "Let the buyer have faith."
          </div>
        </div>

        <div className="margin-auto">
          <div class="cards-list">
            <div class="card 1">
              <div class="card_image">
                
                <img src={terrassenDielen} alt="test" />
              </div>
              <div class="card_title title-white">
                <p>Terrassendielen</p>
              </div>
            </div>

            <div class="card 2">
              <div class="card_image">
              <img src={Bodenbelage} alt="test" />
              </div>
              <div class="card_title title-white">
                <p>Bodenbelage</p>
              </div>
            </div>

            <div class="card 3">
              <div class="card_image">
              <img src={zaunSysteme} alt="test" />
              </div>
              <div class="card_title">
                <p>Zaunsysteme</p>
              </div>
            </div>

            <div class="card 4">
              <div class="card_image">
              <img src={Schwimmbader} alt="test" />
              </div>
              <div class="card_title title-black">
                <p>Schwimmbader</p>
              </div>
            </div>
          </div>
        </div>

        {/* <div className='photos'>
          <div className='photo-card'> 
            <img className='photo-img' src={photo} alt='photo' />
            <div className='photo-label'>Terrassendielen</div>
          </div>
          <div className='photo-card'> 
            <img className='photo-img' src={photo} alt='photo' />
            <div className='photo-label'>Bodenbelage</div>
          </div>
          <div className='photo-card'> 
            <img className='photo-img' src={photo} alt='photo' />
            <div className='photo-label'>Zaunsysteme</div>
          </div>
          <div className='photo-card'> 
            <img className='photo-img' src={photo} alt='photo' />
            <div className='photo-label'>Schwimmbader</div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Testimonal;
