import img0568 from './images/IMG_0568.JPG';
import img0565 from './images/IMG_0565.JPG';
import img0564 from './images/IMG_0564.JPG';
import img0563 from './images/IMG_0563.JPG';

const data3 = [
    {
        "image": img0568,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0565,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0564,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0563,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    }
]

export default data3;