import React from "react";
import { Link } from "react-scroll";
import logo from "../images/logo.jpg";
import "./navbar.css";
import {FaBars, FaTimes} from "react-icons/fa";
import { useRef } from "react";

function Navbar () { 
    const navRef = useRef();

    const showNavbar = () => {
      navRef.current.classList.toggle("responsive_nav"); 
    }

  return (
    <div className="navbar">
      <img className="logoresponsive" src={logo} alt="logo" />
        <nav className="nav" ref={navRef}>
          <Link to="main">
            <img className="logo" src={logo} alt="logo" />
          </Link>
          <ul className="list">
            <li className="list-element">
              <Link onClick={showNavbar}>Home</Link>
            </li>
            <li className="list-element">
              <Link to="service" smooth={true} duration={500} onClick={showNavbar}>
                Service
              </Link>
            </li>
            <li className="list-element">
              <Link to="testimonal" smooth={true} duration={500} onClick={showNavbar}>
                Testimonal
              </Link>
            </li>
            {/* <li className="list-element">
              <Link to="ContactUs" smooth={true} duration={500}>
                Contact us
              </Link>
            </li> */}
            <li className="list-element">
              <Link to="ServiceArea" smooth={true} duration={500} onClick={showNavbar}>
                Service Area
              </Link>
            </li>
          </ul>
          <button className="contact-us-button">Contact Us</button>
         <button className="nav-btn nav-close-btn"> 
          <FaTimes onClick={showNavbar}/> 
          </button>

        </nav>
        <button onClick={showNavbar} className="nav-btn">
          <FaBars />
        </button>
      </div>
      
  );
}

export default Navbar;
