
import img0542 from './images/IMG_0542.JPG';
import img0545 from './images/IMG_0545.JPG';
import img0543 from './images/IMG_0543.JPG';

const data2 = [
    {
        "image": img0542,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0545,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    },
    {
        "image": img0543,
        "title": "test",
        "subTitle": "testSubtitle",
        "interval": 1500
    }
]

export default data2;