import './header.css';
import '../../index.css';
// import Navbar from '../navbar/Navbar';
// import first from '../navbar/firstphoto.jpeg';
// import second from '../navbar/secondphoto.jpg';
// import third from '../navbar/thirdphoto.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/css';
// import 'swiper/css/effect-coverflow';
// import 'swiper/css/pagination';

import 'swiper/css';
import 'swiper/swiper-bundle.css'

import { EffectCoverflow, Pagination } from 'swiper/modules';

import firstPic from "./IMG_0586.JPG";
import secondPic from "./IMG_0583.JPG";
import thirdPic from "./IMG_0585.JPG";

function Header() {
  return (
   <div className='header-container'>
        <div className="header">
            <div className="header-info">
                <div className="header-margin-top">
                    <span className="header-text">Qualitätsfußböden</span><span className="header-text-brown">für</span>
                    <div className="header-text">Ihr Zuhause</div>
                </div>
                <p className="headet-text-p">Wir schätzen Ihr Vertrauen. Unsere Kunden wählen uns und unsere Produkte, weil sie wissen, dass wir die Besten sind. Holen Sie sich diesen trendigen Look mit unserer unschlagbaren Auswahl.</p>
                <button className='contact-us-button-in-header'>Kontaktieren Sie uns</button>
                <div className="experience">
                    <div className="experince-years">
                        <div className="experince-years-text">20+</div>
                        <div>Jahre Erfahrung</div>
                    </div>
                    <div className="experince-years margin-left">
                        <div className="experince-years-text">25+</div>
                        <div>Erhaltene Auszeichnungen</div>
                    </div>
                    <div className="experince-years margin-left">
                        <div className="experince-years-text">215+</div>
                        <div>Abgeschlossene Projekte</div>
                    </div>
                </div>
            </div>
        </div>
            <div className='header-swiper'>
                <div className='header-margin-top header-swiper-2'>
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                      }}
                    pagination={true}
                    modules={[EffectCoverflow, Pagination]}
                    className="mySwiper"
                >
                    <SwiperSlide>
                    <img className='swiper-image' src={firstPic} alt='test' />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className='swiper-image' src={secondPic} alt='test' />
                    </SwiperSlide>
                    <SwiperSlide>
                    <img className='swiper-image' src={thirdPic} alt='test' />
                    </SwiperSlide>
                </Swiper>
                </div>
            </div>


            {/* <div className="img-header">
                <div className="hi">
                    <div className="header-images">
                        <img className="headers-images" src={first} alt="" />
                        <img className="headers-images" src={second} alt="" /> 
                        <img className="headers-images" src={third} alt="" />
                    </div>
                </div>
                <div className="headers-image-numbers">
                    <div>01</div>
                    <div>02</div>
                    <div>03</div>
                </div>
            </div> */}

            
        
   </div>
  );
}

export default Header;
