import './App.css';
// import ContactUs from './components/contactUs/ContactUs';
import Header from './components/header/Header';
import Service from './components/service/Service';
import ServiceArea from './components/serviceArea/ServiceArea';
import Testimonal from './components/testimonal/Testimonal';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

function App() {
  return (
    <div >
      <Navbar />
      <Header />
      <Service />
      <Testimonal />
      {/* <ContactUs /> */}
      <ServiceArea />
      <Footer />
    </div>
  );
}

export default App;
